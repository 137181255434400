export const apiUrl = "/api/v2/inqueritos";
export const rqModel = "orders";
export const pathname = `${process.env.PUBLIC_URL}/push/encomendas`;

export const columns = [
    {
        name: "Criado em",
        selector: "createdOn",
        sortable: true,
        center: true,
    },
    {
        name: "Nome",
        selector: "name",
        sortable: true,
        grow: 3,
        center: false,
        width:'300px',
        wrap: true
    },
    {
        name: "Tipo",
        selector: "inq_type",
        sortable: true,
        grow: 3,
        center: false,
        width:'120px',
        wrap: true
    },
    {
        name: "Estado",
        selector: "state",
        sortable: true,
        center: true,
    },
    {
        name: "Respostas",
        selector: "responses",
        sortable: true,
        center: true,
    },
    {
        name: "Recebidas",
        selector: "received",
        sortable: true,
        center: true,
    },
    {
        name: "Opções",
        selector: "action",
        sortable: false,
        grow: 2,
        width:'220px',
    },
];

export const schema = [
    {
        colSize: "6",
        label: "Nome da Encomenda",
        name: "name",
        id: "name",
        type: "text",
        required: true,
        requiredMessage: "Este campo é obrigatório",
    },
    {
        colSize: "4",
        label: "Tipo de Inquérito",
        name: "inq_type",
        id: "inq_type",
        type: "select",
        required: true,
        requiredMessage: "Este campo é obrigatório",
        multi: false,
        optionLabel: "label",
        optionValue: "value",
        options: [
            {
                value: "facultativo",
                label: "Opcional",
            },
            {
                value: "tacita",
                label: "Aceite por Defeito",
            },
            {
                value: "tacitap",
                label: "Aceite por Defeito PLUS",
            },
            {
                value: "desafio",
                label: "Desafio Flash",
            },
            {
                value: "direct",
                label: "Direct",
            },
        ],
        controller: [
            {
                onSelect: "facultativo",
          
            },
            {
                onSelect: "obrigatório",
          
            },
            {
                onSelect: "tacita",
          
            },
            {
                onSelect: "tacitap",
          
            },
            {
                onSelect: "direct",
                hide: "investmentFixed-distributionReason-convertsToCredits-copaymentDate-sectionTitlePromo-promo.start-promo.end-hrPromo",
      
            },
            {
                onSelect: "desafio",
                show: "",
            },
        ],
    },
    {
        hidden: false,
        type: "component",
        colSize: "4",
        name: "col",
        id: "colhid",
    },
    {
        hidden: false,
        name: "hr",
        id: "hrhid",
        type: "component",
        className: "mb-4",
    },
    {
        hidden: false,
        colSize: "3",
        label: "Data Prevista Entrega",
        name: "delivery.end",
        id: "delivery.end",
        type: "datepicker",
        required: true,
        requiredMessage: "Este campo é obrigatório",
    },
    {
        hidden: false,
        colSize: "4",
        label: "Data de Fecho da Push",
        name: "end_date",
        id: "end_date",
        type: "datepicker",
        required: true,
        requiredMessage: "Este campo é obrigatório",
    },
    {
        name: "hr",
        id: "hr",
        type: "component",
        className: "mb-4",
    },
    {
        colSize: "4",
        label: "Distribuidor (A Farmácia Pode Escolher Distribuidor)",
        name: "preferred_distribution",
        id: "preferred_distribution",
        type: "select",
        required: true,
        requiredMessage: "Este campo é obrigatório",
        multi: true,
        optionLabel: "label",
        optionValue: "value",
        options: [
            {
                value: "lab",
                label: "Directo - Laboratório",
            },
            {
                value: "plural",
                label: "Plural",
            },
            {
                value: "alliance",
                label: "Alliance",
            },
            {
                value: "udifar",
                label: "Udifar",
            },
            {
                value: "empifarma",
                label: "Empifarma",
            },
        ],
    },
    {
        colSize: "2",
        label: "A Farmácia Pode Escolher Distribuidor",
        name: "is_choice_distribution",
        id: "is_choice_distribution",
        type: "checkbox",
    },
    {
        colSize: "6",
        label: "Fornecedor Associado",
        name: "fornecedor",
        id: "fornecedor",
        type: "select",
        required: true,
        requiredMessage: "Este campo é obrigatório",
        multi: false,
        options: null,
        optionLabel: "name",
        optionValue: "fornecedor_id",
        api: {
            route: "/api/v1/laboratories",
            redux: "Laboratories",
        },
    },
    {
        colSize: "6",
        label: "Investimento Fixo",
        name: "investmentFixed",
        id: "investmentFixed",
        type: "number",
        required: true,
        requiredMessage: "Este campo é obrigatório",
    },
    {
        colSize: "6",
        label: "Motivo Distribuição",
        name: "distributionReason",
        id: "distributionReason",
        type: "select",
        required: true,
        requiredMessage: "Este campo é obrigatório",
        multi: false,
        optionLabel: "label",
        optionValue: "value",
        options: [
            {
                value: "farmaciamais",
                label: "Farmácia mais",
            },
            {
                value: "montra",
                label: "Montra",
            },
            {
                value: "mupi",
                label: "Mupi",
            },
            {
                value: "formacaopresencial",
                label: "Formação Presencial",
            },
            {
                value: "webinar",
                label: "Webinar",
            },
            {
                value: "eleraning",
                label: "E-learning",
            },
            {
                value: "rastreino",
                label: "Rastreio",
            },
            {
                value: "descontoadicional",
                label: "Desconto Adicional",
            },
            
            {
                value: "promocional",
                label: "Promocional",
            },
            {
                value: "lancamento",
                label: "Lançamento",
            },
            {
                value: "campanha",
                label: "Campanha",
            },
            {
                value: "outro",
                label: "Outro",
            },
        ],
    },
    {
        colSize: "6",
        label: "Créditos na Loja",
        name: "convertsToCredits",
        id: "convertsToCredits",
        type: "number",
        required: true,
        requiredMessage: "Este campo é obrigatório",
    },
    {
        colSize: "6",
        label: "Data Copagamento",
        name: "copaymentDate",
        id: "copaymentDate",
        type: "datepicker",
        required: true,
        requiredMessage: "Este campo é obrigatório",
    },
    {
        name: "hr",
        id: "hr",
        type: "component",
        className: "mb-4",
    },
    {
        title: "Data de Promoção",
        name: "sectionTitle",
        id: "sectionTitlePromo",
        type: "sectionTitle",
    },
    {
        colSize: "3",
        label: "De",
        name: "promo.start",
        id: "promo.start",
        type: "datepicker",
        required: true,
        requiredMessage: "Este campo é obrigatório",
    },
    {
        colSize: "3",
        label: "A",
        name: "promo.end",
        id: "promo.end",
        type: "datepicker",
        required: true,
        requiredMessage: "Este campo é obrigatório",
    },
    {
        name: "hr",
        id: "hrPromo",
        type: "component",
        className: "mb-4 mt-4",
    },
    {
        colSize: "12",
        label: "Ficheiro xls de Segmentação",
        name: "file",
        id: "file",
        type: "file",
        content: "file",
        multi: false,
        validate: "push",
    },
    {
        colSize: "12",
        label: "Descrição",
        name: "description",
        id: "description",
        type: "froala",
    },
];
